import jQuery from 'jquery';
import popper from 'popper.js';
import bootstrap from 'bootstrap';
window.$ = jQuery;
import '../scss/style.scss';


$(document).ready(function(){
  let menuBurger = $(".menu-burger");
  let menuWrapper = $(".menu-wrapper-mobile");
  menuBurger.click(function () {
    menuBurger.toggleClass('active');
    menuWrapper.toggleClass('active');
  });

  let dropDownButton = $(".menu-item-drop");
  let dropDownWrapper = $(".menu-item-drop-wrapper");
  dropDownButton.click(function () {
    dropDownWrapper.toggleClass('active');
  });

  let dropDownBurgerButton = $(".menu-item-drop-burger");
  let dropDownBurgerWrapper = $(".menu-item-drop-burger-wrapper");
  dropDownBurgerButton.click(function () {
    dropDownBurgerWrapper.toggleClass('active');
    dropDownBurgerButton.toggleClass('active');
  });

  let dropDownMobileButton = $(".menu-item-mobile-drop");
  let dropDownMobileWrapper = $(".menu-item-mobile-drop-wrapper");
  dropDownMobileButton.click(function () {
    dropDownMobileWrapper.toggleClass('active');
  });

  let images = [], i = 0;
  $(".banner-slider-img").each(function() {
    images[i] = (this.src);
    i++
  });
  let active_img = {
    img: $(".banner-slider-img-active"),
    nextImg : $(".banner-slider-img-active-second"),
    num: 0,
    next : 1
  };

  function nextImg(){
    active_img.num++;
    active_img.next++;
    active_img.img.fadeTo(1000, 0, function() {
      active_img.img.attr('src', images[active_img.num % images.length])
      active_img.img.fadeTo(1000, 1, function () {
        active_img.nextImg.attr('src', images[active_img.next % images.length])
      });
    })
  }
  if ($(".banner-slider-img")[0]) {
    let timerId = setInterval(function() {
      nextImg()
    }, 5000);
  }


});